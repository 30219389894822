






































import { Observer } from "mobx-vue";
import { Component, Vue, Provide, Watch, Inject } from "vue-property-decorator";
import { TrainingGroundViewmodel } from "../viewmodels/training-ground-viewmodel";
import { StakeBoosters } from "@/constants/index";

@Observer
@Component({})
export default class APRDetailTable extends Vue {
  @Inject() vm!: TrainingGroundViewmodel;
  boosters = StakeBoosters;
}
